*{
  margin: 0;
  padding: 0;
}
body{
  background-color: #f8f9fa;
}
.sideBar.active{
  width: 300px;
}
.sideBar{
  position: fixed;
  width: 70px;
  background-color: #1D3557;
  height: 100%;
  overflow: hidden;
  transition: all 0.25s ease-in;
}

.sideBar.active ~ .pages{
  left: 300px;
  width: calc(100% - 300px);
}

.pages{
  position: absolute;
  left: 70px;
  height: 100%;
  width: calc(100% - 70px);
  /* width: calc(100% - 80px); */
  transition: all 0.25s ease-in;
}
